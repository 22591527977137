import React from 'react';
import Footer from './components/footer'
import { Banner } from './components/banner'
import Article from './components/article'
import Box, { BoxProps } from './components/box'
import { Home_Intro, Home_Boxes } from './config'

const { Home_Banner_Pages } = window.config_data

export default class HomePage extends React.Component {

    render_boxes = (boxes: BoxProps[]) => {
        return boxes.map(b => <Box
            title={b.title}
            pre_title={b.pre_title}
            image_url={b.image_url}
            text={b.text}
            button_text={b.button_text}
            button_url={b.button_url}
        ></Box>)
    }

    render() {
        let controls = []

        controls.push(<Banner pages={Home_Banner_Pages} />)

        controls.push(<Article
            pre_title={Home_Intro.pre_title}
            title={Home_Intro.title}
            text={Home_Intro.text}
        />)

        controls.push(<section id="one" className="wrapper style2">
            <div className="inner">
                <div className="grid-style">
                    {this.render_boxes(Home_Boxes)}
                </div>
            </div>
        </section>)

        controls.push(<Footer />)

        return controls
    }
}
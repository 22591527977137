import React from 'react';

type HeaderProps = {
    title: string
    url: string
}

export default class HeaderComponent extends React.Component<HeaderProps, HeaderProps> {
    constructor(props) {
        super(props);
        this.state = props
    }

    render() {
        return <header id="header">
            <div className="logo"><a href={this.state.url}><span>{this.state.title}</span></a></div>
            {/* <a href="#menu">Menu</a> */}
        </header>
    }
}
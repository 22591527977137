import React from 'react';

export enum ImageAlign {
    left = "left",
    right = "right",
}

export type ArticleText = {
    image?: string
    image_align?: ImageAlign
    text: string
}

export type ArticleProps = {
    title: string
    pre_title: string
    text: ArticleText[]
}

export default class Article extends React.Component<ArticleProps, ArticleProps> {
    constructor(props) {
        super(props);
        this.state = props
    }

    render_text(text: ArticleText) {

        let tt = []
        let t = text.text.split('\r\n')
        if (t.length === 0) {
            tt.push(t)
        }
        else {
            t.forEach(x => {
                tt.push(x)
                tt.push(<br />)
            })
        }

        if (text.image !== null) {
            return <p><span className={`image ${text.image_align}`}><img src={text.image} alt="" /></span>{tt}</p>
        }

        return <p>{tt}</p>
    }

    render() {
        return <section id="two" className="wrapper style2">
            <div className="inner">
                <div className="box">
                    <div className="content">
                        <header className="align-center">
                            <p>{this.state.pre_title}</p>
                            <h2>{this.state.title}</h2>
                        </header>
                        {this.state.text.map(t => this.render_text(t))}
                    </div>
                </div>
            </div>
        </section>
    }
}
import React from 'react';
import Footer from './components/footer'
import Header from './components/header'
import { SmallBanner } from './components/banner'

export default class WriteUpPage extends React.Component {

    constructor(props) {
        super(props)
    }

    render() {

        let controls = []
        let main = []

        controls.push(<Header
            title="Home"
            url="/" />)

        controls.push(<SmallBanner
            title="Write Up"
            pre_title="One way to cross an ocean" />)

        let refit = <div>
            <h3>Refitting the boat</h3>
            <p>Just before I left the UK, I left my job and sailed as much as I could before putting Miranda ashore. I carefully examined all the yards along the south coast,
            weighing the pros and cons and then just went with the cheapest one I could fine. Hayling Yacht Company on Hayling Island. The waters of Hayling Island on nautical
            charts are green, indicating that it dries out. It's really just a marsh that floods twice a day so unless you sail a motorised bathtub, anything with a keel restricts
            you to about an hour either side of high water. Luckily for me, the day I arranged the lift high tide was around 3pm.
            I watched as she was grabbed by the hydraulic lifting machine, pressure washed and trundled out of side to be shored up. I trundled myself back to London.
            </p>
            <p>To qualify as a boat the first thing any vessel should do is keep the water on the outside. The trouble is, boats are full of holes.</p>
            <p>Mine has 7 below the waterline:
                <ol>
                    <li>Cooling water intake for the engine</li>
                    <li>Galley sink waste</li>
                    <li>Depth transducer</li>
                    <li>Log impeller</li>
                    <li>Toilet flush in</li>
                    <li>Toilet flush out</li>
                    <li>Head basin waste</li>
                </ol>

Some were original, others have definitely been replaced since Miranda was launched, but seeing as I'm going to slap her up and down in big waves it's prudent to replace them whilst the boat is out of the water. So I did. Well, <i>I</i> didn't, some friends of mine who know what they're doing did.
            </p>
            {
                // <Thumbnails images={
                //     [
                //         { image_url: "images/refit/seacocks/8.jpg" },
                //         { image_url: "images/refit/seacocks/9.jpg" },
                //         { image_url: "images/refit/seacocks/10.jpg" },
                //         { image_url: "images/refit/seacocks/14.jpg" },
                //         { image_url: "images/refit/seacocks/15.jpg" },
                //         { image_url: "images/refit/seacocks/12.jpg" },
                //     ]
                // }></Thumbnails>
            }
            <p>With nice shiny seacocks it's almost a pleasure opening and closing them. Especially the Blake's.</p>
            <p>Another quality for a boat to have is a mast. The one in my case <i>rests</i> upon the cabin house and held in place by 2 forestays, a back stay and 4 shrouds. The standing rigging on Miranda has been "partially replaced" in the last 10 years and although showed no signs of damage or wear, I chose to replace it.</p>
            <p></p>
        </div>

        main.push(refit)

        controls.push(<div className="container">{main}</div>)

        controls.push(<Footer />)

        return controls
    }
}
import React from 'react';

export default class FooterComponent extends React.Component {
    render() {
        return <footer id="footer">
            <div className="container">
                <ul className="icons">
                    <li><a href="https://www.facebook.com/sailingmiranda" className="icon fa-facebook"><span className="label">Facebook</span></a></li>
                    <li><a href="mailto:sailing.vessel.miranda@gmail.com" target="_blank" className="icon fa-envelope-o"><span
                        className="label">Email</span></a></li>
                </ul>
            </div>
            <div className="copyright">
                &copy; sv-miranda.com All rights reserved.
    </div>
        </footer>
    }
}
import React from 'react';

export type BannerPageProps = {
    title: string
    pre_title: string
    image_url: string
}

type BannerProps = {
    pages: BannerPageProps[]
}

export class Banner extends React.Component<BannerProps, BannerProps> {
    constructor(props) {
        super(props);
        this.state = props
    }

    render() {
        return <section className="banner full">
            {this.props.pages.map(p =>
                <article>
                    <img src={p.image_url} alt="" />
                    <div className="inner">
                        <header>
                            <p>{p.pre_title}</p>
                            <h2>{p.title}</h2>
                        </header>
                    </div>
                </article>
            )}
        </section>
    }
}

type SmallBannerProps = {
    title: string
    pre_title: string
}

export class SmallBanner extends React.Component<SmallBannerProps, SmallBannerProps> {
    constructor(props) {
        super(props);
        this.state = props
    }

    render() {
        return <section id="One" className="wrapper style3">
            <div className="inner">
                <header className="align-center">
                    <p>{this.state.pre_title}</p>
                    <h2>{this.state.title}</h2>
                </header>
            </div>
        </section>
    }
}
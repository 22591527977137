import { ArticleProps, ImageAlign } from './components/article'
import { BoxProps } from './components/box'
import { GalleryComponentProps, GalleryImage } from './components/gallery'
import { BannerPageProps } from './components/banner'
import GalleryConfigJson from './images_config.json'
import moment from 'moment'

const _galleryConfig = GalleryConfigJson as GalleryImage[]

export const Miranda_MMSI: string = "235077651"

// Home page
export const Home_Banner_Pages: BannerPageProps[] = [
    {
        image_url: "asdasdadsimages/bg.jpg",
        title: "Miranda",
        pre_title: "A 6,500 mile journey across the ocean on"
    },
    {
        image_url: "/asdasd/UNADJUSTEDNONRAW_thumb_25.jpg",
        title: "Miranda",
        pre_title: "A 6,500 mile journey across the ocean on"
    },
    {
        image_url: "/asdasd/UNADJUSTEDNONRAW_thumb_25.jpg",
        title: "Miranda",
        pre_title: "A 6,500 mile journey across the ocean on"
    }
]

export const Home_Intro: ArticleProps = {
    pre_title: "Gosport > Galicia > Tenerife > Antigua > Bahamas > New York",
    title: "What's this all about?",
    text: [
        {
            image: "/images/course.jpg",
            image_align: ImageAlign.right,
            text: "Well, this isn't the start of a YouTube channel where I sell everything and try to cruise around the world for free by pimping out my wife for clicks...\r\n\r\n" +
                "My wife and I moved to New York City but left the boat behind in the UK, so obviously the smartest thing to do is take it on a 6,500 nautical mile journey to park it up in the Big Apple.\r\n\r\n" +
                "I set sail in early November for the Canaries and after a week of repairs and local beer, embarked on the Atlantic crossing in December. After a festive Christmas of tinned ham and Stollen, arrived on the 28th at Antigua just in time for New Years.\r\n\r\n" +
                "Miranda is currently sitting in a little cradle in the Jolliest of Harbours until March when I'll complete the final leg and bring her home to New York City."
        }
    ]
}

export const Home_Boxes: BoxProps[] = [
    {
        pre_title: "Leg 1",
        title: "Gosport to Tenerife",
        image_url: "images/IMG_20201103_122642.jpg",
        text: "We've had a taste of everything on this leg; standard Solent tides pushing us 8kts+ out to sea, days of being becalmed and a force 8 storm on our last day in Biscay. Major damage to the self steering gear with the blade snapping at first and then the lower assembly just came off completely. A forward seacock has been leaking which requires a bilge pump every 4 hours. A chartplotter broke along with the AIS. Mostly all fixed and our backups worked well.",
        button_text: "Gallery",
        button_url: "/gallery-leg-1"
    },
    {
        pre_title: "Leg 2",
        title: "Tenerife to Antigua",
        image_url: "images/IMG_20201129_170457_01.jpg",
        text: "The big one: The Crossing. Actually not as perilous as people think, as long as you have all the water you can carry and then some. A nice, easy ride for the most part with less wind than predicted. Only took 23 days in the end.",
        button_text: "Gallery",
        button_url: "/gallery-leg-2"
    },
    {
        pre_title: "Preparing for the ocean",
        title: "Boat Refit",
        image_url: "images/refit.jpg",
        text: "A racer-cruiser isn't always equipped for an ocean crossing, but this one will be. Currently lying in Hayling Island, Miranda is going through an extensive overhaul.",
        button_text: "Gallery",
        button_url: "/gallery-refit"
    },
    {
        pre_title: "Where are we?",
        title: "Live Updates",
        image_url: "images/UNADJUSTEDNONRAW_thumb_df.jpg",
        text: "Track our progress, see where we are and send us a message!",
        button_text: "Go",
        button_url: "/live"
    },
    // {
    //     pre_title: "Write Up",
    //     title: "Write Up",
    //     image_url: "images/gallery.jpg",
    //     text: "One way to cross an ocean",
    //     button_text: "Read",
    //     button_url: "/write-up"
    // },
    {
        pre_title: "",
        title: "Gallery",
        image_url: "images/4.jpg",
        text: "Lollying about on the Solent and elsewhere",
        button_text: "Gallery",
        button_url: "/gallery-solent"
    }
]

// Gallery Thumbnails
const arrange = (items: GalleryImage[]): GalleryImage[] => {
    let a = items.filter(x => x.date !== undefined).sort(sort_by_date)
    let b = items.filter(x => x.date === undefined)

    return a.concat(b)
}

const sort_by_date = (a: GalleryImage, b: GalleryImage): number => {
    // if (a.date == undefined) return -1
    // if (b.date == undefined) return -1

    return a.date > b.date ? 1 : -1
}

export const Gallery_Refit_Props: GalleryComponentProps = {
    pre_title: "Preparing for the ocean",
    title: "Refit",
    images: arrange(_galleryConfig.filter(i => i.section == "refit"))
}

export const Gallery_Solent_Props: GalleryComponentProps = {
    pre_title: "Lollying about on the Solent and elsewhere",
    title: "Gallery",
    images: arrange(_galleryConfig.filter(i => i.section == "solent"))
}

export const Gallery_Leg1_Props: GalleryComponentProps = {
    pre_title: "Gosport to Tenerife",
    title: "Gallery",
    images: arrange(_galleryConfig.filter(i => i.section == "leg1"))
}

export const Gallery_Leg2_Props: GalleryComponentProps = {
    pre_title: "Tenerife to Antigua",
    title: "Gallery",
    images: arrange(_galleryConfig.filter(i => i.section == "leg2"))
}

import React from 'react';

export type BoxProps = {
    title: string
    pre_title: string
    image_url: string
    text: string
    button_text: string
    button_url: string
}

export default class BoxComponent extends React.Component<BoxProps, BoxProps> {
    constructor(props) {
        super(props);
        this.state = props
    }

    render() {
        return <div>
            <div className="box">
                <div className="image fit">
                    <img src={this.state.image_url} alt="" />
                </div>
                <div className="content">
                    <header className="align-center">
                        <p>{this.state.pre_title}</p>
                        <h2>{this.state.title}</h2>
                    </header>
                    <p>{this.state.text}</p>
                    <footer className="align-center">
                        <a href={this.state.button_url} className="button alt">{this.state.button_text}</a>
                    </footer>
                </div>
            </div>
        </div>
    }
}
import React, { CSSProperties } from 'react';

type AisMapProps = {
    mmsi: string
    width_pc?: number
    height?: number
}

export default class AisMapComponent extends React.Component<AisMapProps, AisMapProps> {
    constructor(props: AisMapProps) {
        super(props);

        let p: AisMapProps = {
            mmsi: props.mmsi,
            height: props.height || 400,
            width_pc: props.width_pc || 100
        }

        this.state = p
    }

    render() {

        let controls = []

        let height = this.state.height
        let width_pc = this.state.width_pc
        let zoom = 12

        let iframe_ais = <iframe
            name="vesselfinder"
            id="vesselfinder"
            width={`${width_pc}%`}
            height={height}
            src={`https://www.vesselfinder.com/aismap?zoom=${zoom}&amp;width_pc=${width_pc}%&amp;height=${height}&amp;names=false&amp;mmsi=${this.state.mmsi}&amp;track=false&amp;fleet=false&amp;fleet_name=false&amp;fleet_hide_old_positions=false&amp;clicktoact=false&amp;store_pos=true`}>Browser does not support embedded objects.<br />Visit directly <a href="https://www.vesselfinder.com" target="_blank">www.vesselfinder.com</a></iframe>

        let iframe = <iframe
            frameBorder="0"
            // marginWidth='0'
            // marginheight="0"
            width={`${width_pc}%`}
            height={height}
            src="https://share.garmin.com/svmiranda"></iframe>

        let style: CSSProperties = {
            padding: 20
        }

        controls.push(
            < div style={style} >
                {iframe}
            </div >)

        return controls
    }
}
import React from 'react';
import Footer from './components/footer'
import Header from './components/header'
import AisMap from './components/ais_map'
import Blog from './components/blog'
import { SmallBanner } from './components/banner'
import { Miranda_MMSI } from './config'

export default class LivePage extends React.Component {

    constructor(props) {
        super(props)

        this.state = props
    }

    render() {

        let controls = []

        controls.push(<Header
            title="Home"
            url="/" />)

        controls.push(<SmallBanner
            title="Captains Log"
            pre_title="Like Twitter, but shitter" />)

        controls.push(
            <div className="row">
                <div className="12u 12u$(medium)">
                    <AisMap mmsi={Miranda_MMSI} width_pc={100} height={600} />
                </div>
                {/* <div className="12u 12u$(medium)">
                    <Blog />
                </div> */}
            </div>)

        // controls.push(<AisMap mmsi={Miranda_MMSI} />)

        // controls.push(<Blog />)

        controls.push(<Footer />)

        return controls
    }
}
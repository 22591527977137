import React from 'react';
import Footer from './footer'
import Header from './header'
import { SmallBanner } from './banner'
import Gallery from 'react-grid-gallery'
import moment from 'moment';

export interface GalleryImage {
    image_name: string
    image_width: number
    image_height: number

    thumb_name: string
    thumb_width: number
    thumb_height: number

    caption?: string
    date?: number
    section: string
}

export type GalleryComponentProps = {
    title: string
    pre_title: string
    images: GalleryImage[]
}

export default class GalleryComponent extends React.Component<GalleryComponentProps, GalleryComponentProps> {

    constructor(props) {
        super(props)

        this.state = props
    }

    render() {

        let controls = []

        controls.push(<Header
            title="Home"
            url="/" />)

        controls.push(<SmallBanner
            title={this.state.title}
            pre_title={this.state.pre_title} />)

        let images = this.state.images
            .map(i => {
                return {
                    src: i.image_name,
                    thumbnail: i.thumb_name,
                    thumbnailWidth: i.thumb_width,
                    thumbnailHeight: i.thumb_height,
                    caption: `${i.date != null ? moment(i.date).format("LLLL") : ""} ${i.caption || ""}`
                }
            })

        // https://www.npmjs.com/package/react-grid-gallery
        controls.push(<Gallery images={images} enableImageSelection={false}></Gallery>)

        controls.push(<Footer />)

        return controls
    }
}
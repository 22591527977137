import { render } from 'react-dom';
import HomePage from './home';
import LivePage from './live';
import WriteUpPage from './write_up';
import Gallery, { GalleryImage } from './components/gallery'
import { Gallery_Refit_Props, Gallery_Solent_Props, Gallery_Leg1_Props, Gallery_Leg2_Props } from './config'
import React from 'react';
import {
    BrowserRouter as Router,
    Switch,
    Route,
    Redirect
} from 'react-router-dom';


require('dotenv').config();

const GalleryRefit = () => {
    return (
        <Gallery
            title={Gallery_Refit_Props.title}
            pre_title={Gallery_Refit_Props.pre_title}
            images={Gallery_Refit_Props.images} />
    );
}

const GallerySolent = () => {
    return (
        <Gallery
            title={Gallery_Solent_Props.title}
            pre_title={Gallery_Solent_Props.pre_title}
            images={Gallery_Solent_Props.images} />
    );
}

const GalleryLeg1 = () => {
    return (
        <Gallery
            title={Gallery_Leg1_Props.title}
            pre_title={Gallery_Leg1_Props.pre_title}
            images={Gallery_Leg1_Props.images} />
    );
}

const GalleryLeg2 = () => {
    return (
        <Gallery
            title={Gallery_Leg2_Props.title}
            pre_title={Gallery_Leg2_Props.pre_title}
            images={Gallery_Leg2_Props.images} />
    );
}

render((
    <Router>
        <Switch>
            <Route exact path='/' component={HomePage} />
            <Route exact path='/gallery-refit' component={GalleryRefit} />
            <Route exact path='/gallery-leg-1' component={GalleryLeg1} />
            <Route exact path='/gallery-leg-2' component={GalleryLeg2} />
            <Route exact path='/gallery-solent' component={GallerySolent} />
            <Route exact path='/live' component={LivePage} />
            <Route exact path='/write-up' component={WriteUpPage} />
            <Redirect to="/" />
        </Switch>
    </Router>), document.getElementById("root"));
